// Footer

export function Footer() {
  return (
    <footer>
      <small>Isaac Olarewaju, 2025.</small>
      <small></small>
    </footer>
  );
}
